import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#EE3124',
      main: '#00326E',
      dark: '#00A4E5',
      contrastText: '#FFF',
    },
  },
});
