import { Container, Link, Typography } from '@mui/material';

export const Attribution = () => {
  return (
    <Container>
      <Typography
        margin={4}
        variant="body1"
        color="textSecondary"
        align="center"
      >
        For questions please visit{' '}
        <Link href="https://support.stena.com">support.stena.com</Link>
      </Typography>
    </Container>
  );
};
