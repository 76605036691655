import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Divider, Box } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Features = () => {
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid item={true} xs={12}>
        <Divider />
        <Box p={2}>
          <Typography variant="h6" align={'center'}>
            {t('features.title')}
          </Typography>
        </Box>
      </Grid>
      <Feature title={t('features.featureEndToEndTitle')} icon="lock">
        {t('features.featureEndToEndText')}
      </Feature>
      <Feature title={t('features.featureSelfDestructionTitle')} icon="bomb">
        {t('features.featureSelfDestructionText')}
      </Feature>
      <Feature title={t('features.featureOneTimeTitle')} icon="download">
        {t('features.featureOneTimeText')}
      </Feature>
      <Feature title={t('features.featureSimpleSharingTitle')} icon="share-alt">
        {t('features.featureSimpleSharingText')}
      </Feature>
      <Feature
        title={t('features.featureNoAccountsTitle')}
        icon="user-alt-slash"
      >
        {t('features.featureNoAccountsText')}
      </Feature>
      <Feature title={t('features.featureOpenSourceTitle')} icon="code-branch">
        {t('features.featureOpenSourceText')}
      </Feature>
    </Grid>
  );
};

type FeatureProps = {
  readonly title: string;
  readonly icon: IconProp;
  readonly children: JSX.Element;
};

const Feature = (props: FeatureProps) => {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }} item={true}>
      <FontAwesomeIcon color={'black'} size={'1x'} icon={props.icon} />
      <Typography sx={{ padding: '5px' }} variant="body2">
        {props.title}
      </Typography>
    </Grid>
  );
};
