import { AppBar, Toolbar, Box, Link } from '@mui/material';

export const Header = () => {
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar>
        <Link href={home} color="inherit" underline="none">
          <Box
            sx={{
              verticalAlign: 'middle',
              paddingLeft: '5px',
              width: '140px',
              height: '30px',
            }}
            component="img"
            height="40"
            alt=""
            src="stenait.png"
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
